/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserSettings } from './UserSettings';
import { UserSettingsFromJSON, UserSettingsFromJSONTyped, UserSettingsToJSON } from './UserSettings';

/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {string}
   * @memberof User
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  avatar_url: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  phone_number: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  country: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  zip_code: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  state: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  promo_code: string;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  is_new: boolean;
  /**
   *
   * @type {UserSettings}
   * @memberof User
   */
  settings: UserSettings;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'email' in value;
  isInstance = isInstance && 'avatar_url' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'phone_number' in value;
  isInstance = isInstance && 'country' in value;
  isInstance = isInstance && 'city' in value;
  isInstance = isInstance && 'zip_code' in value;
  isInstance = isInstance && 'state' in value;
  isInstance = isInstance && 'address' in value;
  isInstance = isInstance && 'promo_code' in value;
  isInstance = isInstance && 'is_new' in value;
  isInstance = isInstance && 'settings' in value;

  return isInstance;
}

export function UserFromJSON(json: any): User {
  return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    email: json['email'],
    avatar_url: json['avatar_url'],
    status: json['status'],
    name: json['name'],
    phone_number: json['phone_number'],
    country: json['country'],
    city: json['city'],
    zip_code: json['zip_code'],
    state: json['state'],
    address: json['address'],
    promo_code: json['promo_code'],
    is_new: json['is_new'],
    settings: UserSettingsFromJSON(json['settings']),
  };
}

export function UserToJSON(value?: User | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    email: value.email,
    avatar_url: value.avatar_url,
    status: value.status,
    name: value.name,
    phone_number: value.phone_number,
    country: value.country,
    city: value.city,
    zip_code: value.zip_code,
    state: value.state,
    address: value.address,
    promo_code: value.promo_code,
    is_new: value.is_new,
    settings: UserSettingsToJSON(value.settings),
  };
}
