/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  ContentAnalysisResponse,
  ImageDescriptionResponse,
  LabsAlignProductsPlatformsRequest,
  LabsPackShotRequest,
  LabsStudioShotRequest,
  LabsTextToImageRequest,
  LabsTextToSubjectRequest,
} from '../models';
import {
  ContentAnalysisResponseFromJSON,
  ContentAnalysisResponseToJSON,
  ImageDescriptionResponseFromJSON,
  ImageDescriptionResponseToJSON,
  LabsAlignProductsPlatformsRequestFromJSON,
  LabsAlignProductsPlatformsRequestToJSON,
  LabsPackShotRequestFromJSON,
  LabsPackShotRequestToJSON,
  LabsStudioShotRequestFromJSON,
  LabsStudioShotRequestToJSON,
  LabsTextToImageRequestFromJSON,
  LabsTextToImageRequestToJSON,
  LabsTextToSubjectRequestFromJSON,
  LabsTextToSubjectRequestToJSON,
} from '../models';

export interface ImageProcessingApiAnalyzeImageContentRequest {
  image: Blob;
}

export interface ImageProcessingApiImageObjectRemovalRequest {
  refiner_prompt: string;
  refiner_strength: number;
  delete_prompt: string;
  bbox_right: number;
  bbox_bottom: number;
  bbox_left: number;
  bbox_top: number;
  image: Blob;
}

export interface ImageProcessingApiLabsAlignProductsPlatformsOperationRequest {
  labs_align_products_platforms_request?: LabsAlignProductsPlatformsRequest;
}

export interface ImageProcessingApiLabsBlendImagesRequest {
  style_image: Blob;
  content_image: Blob;
}

export interface ImageProcessingApiLabsImageDescriptionRequest {
  image: Blob;
}

export interface ImageProcessingApiLabsPackShotOperationRequest {
  labs_pack_shot_request?: LabsPackShotRequest;
}

export interface ImageProcessingApiLabsRestyleImageRequest {
  image: Blob;
  strictness: number;
  prompt: string;
}

export interface ImageProcessingApiLabsStudioShotOperationRequest {
  labs_studio_shot_request?: LabsStudioShotRequest;
}

export interface ImageProcessingApiLabsTextToGraphicDesignRequest {
  labs_text_to_image_request?: LabsTextToImageRequest;
}

export interface ImageProcessingApiLabsTextToImageOperationRequest {
  labs_text_to_image_request?: LabsTextToImageRequest;
}

export interface ImageProcessingApiLabsTextToSubjectOperationRequest {
  labs_text_to_subject_request?: LabsTextToSubjectRequest;
}

export interface ImageProcessingApiLabsVirtualStagingRequest {
  staging_prompt: string;
  avoid_areas: string;
  room: Blob;
}

export interface ImageProcessingApiSegmentImageBackgroundRequest {
  render: SegmentImageBackgroundRenderEnum;
  image: Blob;
}

/**
 *
 */
export class ImageProcessingApi extends runtime.BaseAPI {
  /**
   */
  async analyzeImageContentRaw(
    requestParameters: ImageProcessingApiAnalyzeImageContentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ContentAnalysisResponse>> {
    if (requestParameters.image === null || requestParameters.image === undefined) {
      throw new runtime.RequiredError('image', 'Required parameter requestParameters.image was null or undefined when calling analyzeImageContent.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    const response = await this.request(
      {
        path: `/image_processing/analyze_content`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ContentAnalysisResponseFromJSON(jsonValue));
  }

  /**
   */
  async analyzeImageContent(image: Blob, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContentAnalysisResponse> {
    const response = await this.analyzeImageContentRaw({ image: image }, initOverrides);
    return await response.value();
  }

  /**
   */
  async imageObjectRemovalRaw(
    requestParameters: ImageProcessingApiImageObjectRemovalRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    if (requestParameters.refiner_prompt === null || requestParameters.refiner_prompt === undefined) {
      throw new runtime.RequiredError(
        'refiner_prompt',
        'Required parameter requestParameters.refiner_prompt was null or undefined when calling imageObjectRemoval.',
      );
    }

    if (requestParameters.refiner_strength === null || requestParameters.refiner_strength === undefined) {
      throw new runtime.RequiredError(
        'refiner_strength',
        'Required parameter requestParameters.refiner_strength was null or undefined when calling imageObjectRemoval.',
      );
    }

    if (requestParameters.delete_prompt === null || requestParameters.delete_prompt === undefined) {
      throw new runtime.RequiredError(
        'delete_prompt',
        'Required parameter requestParameters.delete_prompt was null or undefined when calling imageObjectRemoval.',
      );
    }

    if (requestParameters.bbox_right === null || requestParameters.bbox_right === undefined) {
      throw new runtime.RequiredError('bbox_right', 'Required parameter requestParameters.bbox_right was null or undefined when calling imageObjectRemoval.');
    }

    if (requestParameters.bbox_bottom === null || requestParameters.bbox_bottom === undefined) {
      throw new runtime.RequiredError('bbox_bottom', 'Required parameter requestParameters.bbox_bottom was null or undefined when calling imageObjectRemoval.');
    }

    if (requestParameters.bbox_left === null || requestParameters.bbox_left === undefined) {
      throw new runtime.RequiredError('bbox_left', 'Required parameter requestParameters.bbox_left was null or undefined when calling imageObjectRemoval.');
    }

    if (requestParameters.bbox_top === null || requestParameters.bbox_top === undefined) {
      throw new runtime.RequiredError('bbox_top', 'Required parameter requestParameters.bbox_top was null or undefined when calling imageObjectRemoval.');
    }

    if (requestParameters.image === null || requestParameters.image === undefined) {
      throw new runtime.RequiredError('image', 'Required parameter requestParameters.image was null or undefined when calling imageObjectRemoval.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.refiner_prompt !== undefined) {
      formParams.append('refiner_prompt', requestParameters.refiner_prompt as any);
    }

    if (requestParameters.refiner_strength !== undefined) {
      formParams.append('refiner_strength', requestParameters.refiner_strength as any);
    }

    if (requestParameters.delete_prompt !== undefined) {
      formParams.append('delete_prompt', requestParameters.delete_prompt as any);
    }

    if (requestParameters.bbox_right !== undefined) {
      formParams.append('bbox_right', requestParameters.bbox_right as any);
    }

    if (requestParameters.bbox_bottom !== undefined) {
      formParams.append('bbox_bottom', requestParameters.bbox_bottom as any);
    }

    if (requestParameters.bbox_left !== undefined) {
      formParams.append('bbox_left', requestParameters.bbox_left as any);
    }

    if (requestParameters.bbox_top !== undefined) {
      formParams.append('bbox_top', requestParameters.bbox_top as any);
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    const response = await this.request(
      {
        path: `/image_processing/object_removal`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async imageObjectRemoval(
    refiner_prompt: string,
    refiner_strength: number,
    delete_prompt: string,
    bbox_right: number,
    bbox_bottom: number,
    bbox_left: number,
    bbox_top: number,
    image: Blob,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Blob> {
    const response = await this.imageObjectRemovalRaw(
      {
        refiner_prompt: refiner_prompt,
        refiner_strength: refiner_strength,
        delete_prompt: delete_prompt,
        bbox_right: bbox_right,
        bbox_bottom: bbox_bottom,
        bbox_left: bbox_left,
        bbox_top: bbox_top,
        image: image,
      },
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async labsAlignProductsPlatformsRaw(
    requestParameters: ImageProcessingApiLabsAlignProductsPlatformsOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/image_processing/labs-align-products-platforms`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: LabsAlignProductsPlatformsRequestToJSON(requestParameters.labs_align_products_platforms_request),
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async labsAlignProductsPlatforms(
    labs_align_products_platforms_request?: LabsAlignProductsPlatformsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Blob> {
    const response = await this.labsAlignProductsPlatformsRaw({ labs_align_products_platforms_request: labs_align_products_platforms_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async labsBlendImagesRaw(
    requestParameters: ImageProcessingApiLabsBlendImagesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    if (requestParameters.style_image === null || requestParameters.style_image === undefined) {
      throw new runtime.RequiredError('style_image', 'Required parameter requestParameters.style_image was null or undefined when calling labsBlendImages.');
    }

    if (requestParameters.content_image === null || requestParameters.content_image === undefined) {
      throw new runtime.RequiredError(
        'content_image',
        'Required parameter requestParameters.content_image was null or undefined when calling labsBlendImages.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.style_image !== undefined) {
      formParams.append('style_image', requestParameters.style_image as any);
    }

    if (requestParameters.content_image !== undefined) {
      formParams.append('content_image', requestParameters.content_image as any);
    }

    const response = await this.request(
      {
        path: `/image_processing/labs-blend-images`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async labsBlendImages(style_image: Blob, content_image: Blob, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
    const response = await this.labsBlendImagesRaw({ style_image: style_image, content_image: content_image }, initOverrides);
    return await response.value();
  }

  /**
   */
  async labsImageDescriptionRaw(
    requestParameters: ImageProcessingApiLabsImageDescriptionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ImageDescriptionResponse>> {
    if (requestParameters.image === null || requestParameters.image === undefined) {
      throw new runtime.RequiredError('image', 'Required parameter requestParameters.image was null or undefined when calling labsImageDescription.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    const response = await this.request(
      {
        path: `/image_processing/labs-image-description`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ImageDescriptionResponseFromJSON(jsonValue));
  }

  /**
   */
  async labsImageDescription(image: Blob, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImageDescriptionResponse> {
    const response = await this.labsImageDescriptionRaw({ image: image }, initOverrides);
    return await response.value();
  }

  /**
   */
  async labsPackShotRaw(
    requestParameters: ImageProcessingApiLabsPackShotOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/image_processing/labs-pack-shot`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: LabsPackShotRequestToJSON(requestParameters.labs_pack_shot_request),
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async labsPackShot(labs_pack_shot_request?: LabsPackShotRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
    const response = await this.labsPackShotRaw({ labs_pack_shot_request: labs_pack_shot_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async labsRestyleImageRaw(
    requestParameters: ImageProcessingApiLabsRestyleImageRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    if (requestParameters.image === null || requestParameters.image === undefined) {
      throw new runtime.RequiredError('image', 'Required parameter requestParameters.image was null or undefined when calling labsRestyleImage.');
    }

    if (requestParameters.strictness === null || requestParameters.strictness === undefined) {
      throw new runtime.RequiredError('strictness', 'Required parameter requestParameters.strictness was null or undefined when calling labsRestyleImage.');
    }

    if (requestParameters.prompt === null || requestParameters.prompt === undefined) {
      throw new runtime.RequiredError('prompt', 'Required parameter requestParameters.prompt was null or undefined when calling labsRestyleImage.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    if (requestParameters.strictness !== undefined) {
      formParams.append('strictness', requestParameters.strictness as any);
    }

    if (requestParameters.prompt !== undefined) {
      formParams.append('prompt', requestParameters.prompt as any);
    }

    const response = await this.request(
      {
        path: `/image_processing/labs-restyle-image`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async labsRestyleImage(image: Blob, strictness: number, prompt: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
    const response = await this.labsRestyleImageRaw({ image: image, strictness: strictness, prompt: prompt }, initOverrides);
    return await response.value();
  }

  /**
   */
  async labsStudioShotRaw(
    requestParameters: ImageProcessingApiLabsStudioShotOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/image_processing/labs-studio-shot`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: LabsStudioShotRequestToJSON(requestParameters.labs_studio_shot_request),
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async labsStudioShot(labs_studio_shot_request?: LabsStudioShotRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
    const response = await this.labsStudioShotRaw({ labs_studio_shot_request: labs_studio_shot_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async labsTextToGraphicDesignRaw(
    requestParameters: ImageProcessingApiLabsTextToGraphicDesignRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/image_processing/labs-text-to-graphic-design`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: LabsTextToImageRequestToJSON(requestParameters.labs_text_to_image_request),
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async labsTextToGraphicDesign(
    labs_text_to_image_request?: LabsTextToImageRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Blob> {
    const response = await this.labsTextToGraphicDesignRaw({ labs_text_to_image_request: labs_text_to_image_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async labsTextToImageRaw(
    requestParameters: ImageProcessingApiLabsTextToImageOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/image_processing/labs-text-to-image`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: LabsTextToImageRequestToJSON(requestParameters.labs_text_to_image_request),
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async labsTextToImage(labs_text_to_image_request?: LabsTextToImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
    const response = await this.labsTextToImageRaw({ labs_text_to_image_request: labs_text_to_image_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async labsTextToSubjectRaw(
    requestParameters: ImageProcessingApiLabsTextToSubjectOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/image_processing/labs-text-to-subject`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: LabsTextToSubjectRequestToJSON(requestParameters.labs_text_to_subject_request),
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async labsTextToSubject(labs_text_to_subject_request?: LabsTextToSubjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
    const response = await this.labsTextToSubjectRaw({ labs_text_to_subject_request: labs_text_to_subject_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async labsVirtualStagingRaw(
    requestParameters: ImageProcessingApiLabsVirtualStagingRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    if (requestParameters.staging_prompt === null || requestParameters.staging_prompt === undefined) {
      throw new runtime.RequiredError(
        'staging_prompt',
        'Required parameter requestParameters.staging_prompt was null or undefined when calling labsVirtualStaging.',
      );
    }

    if (requestParameters.avoid_areas === null || requestParameters.avoid_areas === undefined) {
      throw new runtime.RequiredError('avoid_areas', 'Required parameter requestParameters.avoid_areas was null or undefined when calling labsVirtualStaging.');
    }

    if (requestParameters.room === null || requestParameters.room === undefined) {
      throw new runtime.RequiredError('room', 'Required parameter requestParameters.room was null or undefined when calling labsVirtualStaging.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.staging_prompt !== undefined) {
      formParams.append('staging_prompt', requestParameters.staging_prompt as any);
    }

    if (requestParameters.avoid_areas !== undefined) {
      formParams.append('avoid_areas', requestParameters.avoid_areas as any);
    }

    if (requestParameters.room !== undefined) {
      formParams.append('room', requestParameters.room as any);
    }

    const response = await this.request(
      {
        path: `/image_processing/labs-virtual-staging`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async labsVirtualStaging(staging_prompt: string, avoid_areas: string, room: Blob, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
    const response = await this.labsVirtualStagingRaw({ staging_prompt: staging_prompt, avoid_areas: avoid_areas, room: room }, initOverrides);
    return await response.value();
  }

  /**
   */
  async segmentImageBackgroundRaw(
    requestParameters: ImageProcessingApiSegmentImageBackgroundRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    if (requestParameters.render === null || requestParameters.render === undefined) {
      throw new runtime.RequiredError('render', 'Required parameter requestParameters.render was null or undefined when calling segmentImageBackground.');
    }

    if (requestParameters.image === null || requestParameters.image === undefined) {
      throw new runtime.RequiredError('image', 'Required parameter requestParameters.image was null or undefined when calling segmentImageBackground.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.render !== undefined) {
      formParams.append('render', requestParameters.render as any);
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    const response = await this.request(
      {
        path: `/image_processing/segment_background`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async segmentImageBackground(
    render: SegmentImageBackgroundRenderEnum,
    image: Blob,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Blob> {
    const response = await this.segmentImageBackgroundRaw({ render: render, image: image }, initOverrides);
    return await response.value();
  }
}

/**
 * @export
 */
export const SegmentImageBackgroundRenderEnum = {
  Image: 'image',
  Mask: 'mask',
} as const;
export type SegmentImageBackgroundRenderEnum = (typeof SegmentImageBackgroundRenderEnum)[keyof typeof SegmentImageBackgroundRenderEnum];
