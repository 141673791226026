//
import { IProcessedFile } from 'src/utils/ProcessedFile';
//

// ----------------------------------------------------------------------

type Props = {
  file: IProcessedFile | string | null;
};

export default function SingleFilePreview({ file }: Props) {
  if (!file) {
    return null;
  }

  const imgUrl = typeof file === 'string' ? file : file.preview;

  return (
    <img
      alt='file preview'
      src={imgUrl}
      style={{
        display: 'block',
        top: 8,
        left: 8,
        zIndex: 8,
        borderRadius: 1,
        width: 'calc(100% - 16px)',
        height: 'calc(100% - 16px)',
      }}
    />
  );
}
