import { CircularProgress, InputAdornment, TextField } from '@mui/material';
import { debounce } from 'lodash';
import { ChangeEvent, useRef, useState } from 'react';
import { useEvent } from '../../hooks/useEvent';

export interface UploadByUrlProps {
  onFetched: (files: File[]) => Promise<void>;
  acceptedMimetypes: string[];
}

export function UploadByUrl({ onFetched, acceptedMimetypes }: UploadByUrlProps) {
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const valueChangedHandler = useEvent(async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const url = e.target.value;

      setIsFetching(true);
      const headRequestResponse = await fetch(url, { method: 'HEAD', mode: 'cors' });
      const mimeType = headRequestResponse.headers.get('content-type');

      if (!acceptedMimetypes.includes(mimeType ?? '')) {
        setIsFetching(false);
        setError('Cannot fetch file of this type');
        return;
      }

      const fullRequestResponse = await fetch(url, { mode: 'cors' });
      const content = await fullRequestResponse.blob();

      await onFetched([new File([content], 'my-file')]);
    } catch (e) {
      setError('Could not fetch file from provided URL');
      setIsFetching(false);
    }
  });

  const debouncedValueChangedHandler = useRef(debounce(valueChangedHandler, 500)).current;

  return (
    <>
      <TextField
        fullWidth
        size='small'
        error={Boolean(error)}
        helperText={error}
        onChange={debouncedValueChangedHandler}
        disabled={isFetching}
        InputProps={{
          endAdornment: isFetching ? (
            <InputAdornment position='end'>
              <CircularProgress size={16} />
            </InputAdornment>
          ) : (
            <></>
          ),
        }}
      />
    </>
  );
}
