/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Scene
 */
export interface Scene {
  /**
   *
   * @type {string}
   * @memberof Scene
   */
  image_url?: string;
  /**
   *
   * @type {string}
   * @memberof Scene
   */
  category: string;
  /**
   *
   * @type {string}
   * @memberof Scene
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Scene
   */
  id: string;
  /**
   *
   * @type {boolean}
   * @memberof Scene
   */
  editors_pick: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Scene
   */
  user_created: boolean;
}

/**
 * Check if a given object implements the Scene interface.
 */
export function instanceOfScene(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'category' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'editors_pick' in value;
  isInstance = isInstance && 'user_created' in value;

  return isInstance;
}

export function SceneFromJSON(json: any): Scene {
  return SceneFromJSONTyped(json, false);
}

export function SceneFromJSONTyped(json: any, ignoreDiscriminator: boolean): Scene {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    image_url: !exists(json, 'image_url') ? undefined : json['image_url'],
    category: json['category'],
    name: json['name'],
    id: json['id'],
    editors_pick: json['editors_pick'],
    user_created: json['user_created'],
  };
}

export function SceneToJSON(value?: Scene | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    image_url: value.image_url,
    category: value.category,
    name: value.name,
    id: value.id,
    editors_pick: value.editors_pick,
    user_created: value.user_created,
  };
}
