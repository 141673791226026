import { ErrorBoundary, Provider } from '@rollbar/react';

import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { MotionLazyContainer } from './components/animate';
import SnackbarProvider from './components/snackbar';
import Router from './routes';
import ThemeProvider from './theme';
import analytics from './utils/analytics';

// ----------------------------------------------------------------------

export default function App() {
  const rollbarConfig = {
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
    environment: process.env.REACT_APP_ENV,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: process.env.REACT_APP_ENV !== 'development',
    payload: {
      client: {
        javascript: {
          code_version: process.env.REACT_APP_VERSION,
          source_map_enabled: true,
        },
      },
    },
  };
  const location = useLocation();
  useEffect(() => {
    analytics.track({ event_name: 'page_load' });
  }, [location]);

  useEffect(() => {
    if (process.env.REACT_APP_HEAP_ENVIRONMENT_ID) {
      // this is not the heap variable that is defined in analytics
      // but the loader that is defined in index.html
      // heap overwrites the loader with the actual SDK so it might be a bit confusing
      // @ts-ignore
      heap.load(process.env.REACT_APP_HEAP_ENVIRONMENT_ID);
    }
  }, []);

  return (
    <MotionLazyContainer>
      <Provider config={rollbarConfig}>
        <ErrorBoundary>
          <ThemeProvider>
            <SnackbarProvider>
              <Router />
            </SnackbarProvider>
          </ThemeProvider>
        </ErrorBoundary>
      </Provider>
    </MotionLazyContainer>
  );
}
