/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UpdateUserSettingsRequest
 */
export interface UpdateUserSettingsRequest {
  /**
   *
   * @type {number}
   * @memberof UpdateUserSettingsRequest
   */
  photoshoot_batch_size: number;
}

/**
 * Check if a given object implements the UpdateUserSettingsRequest interface.
 */
export function instanceOfUpdateUserSettingsRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'photoshoot_batch_size' in value;

  return isInstance;
}

export function UpdateUserSettingsRequestFromJSON(json: any): UpdateUserSettingsRequest {
  return UpdateUserSettingsRequestFromJSONTyped(json, false);
}

export function UpdateUserSettingsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserSettingsRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    photoshoot_batch_size: json['photoshoot_batch_size'],
  };
}

export function UpdateUserSettingsRequestToJSON(value?: UpdateUserSettingsRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    photoshoot_batch_size: value.photoshoot_batch_size,
  };
}
