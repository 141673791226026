/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type { SocialPost, UpdateSocialPostRequest } from '../models';
import { SocialPostFromJSON, SocialPostToJSON, UpdateSocialPostRequestFromJSON, UpdateSocialPostRequestToJSON } from '../models';

export interface SocialPostsApiCreateSocialPostRequest {
  update_social_post_request?: UpdateSocialPostRequest;
}

export interface SocialPostsApiUpdateSocialPostOperationRequest {
  update_social_post_request?: UpdateSocialPostRequest;
}

/**
 *
 */
export class SocialPostsApi extends runtime.BaseAPI {
  /**
   */
  async createSocialPostRaw(
    requestParameters: SocialPostsApiCreateSocialPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SocialPost>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/social_posts`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateSocialPostRequestToJSON(requestParameters.update_social_post_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SocialPostFromJSON(jsonValue));
  }

  /**
   */
  async createSocialPost(
    update_social_post_request?: UpdateSocialPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SocialPost> {
    const response = await this.createSocialPostRaw({ update_social_post_request: update_social_post_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async updateSocialPostRaw(
    requestParameters: SocialPostsApiUpdateSocialPostOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SocialPost>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/social_posts`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateSocialPostRequestToJSON(requestParameters.update_social_post_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SocialPostFromJSON(jsonValue));
  }

  /**
   */
  async updateSocialPost(
    update_social_post_request?: UpdateSocialPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SocialPost> {
    const response = await this.updateSocialPostRaw({ update_social_post_request: update_social_post_request }, initOverrides);
    return await response.value();
  }
}
