/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type { InferEditActionRequest, InferEditActionResponse } from '../models';
import { InferEditActionRequestFromJSON, InferEditActionRequestToJSON, InferEditActionResponseFromJSON, InferEditActionResponseToJSON } from '../models';

export interface LabsApiCreateCinemagraphRequest {
  mask_range_max: number;
  mask_range_min: number;
  negative_prompt: string;
  positive_prompt: string;
  image: Blob;
}

export interface LabsApiInferEditActionOperationRequest {
  infer_edit_action_request?: InferEditActionRequest;
}

export interface LabsApiLabsRestyleSegmentRequest {
  image: Blob;
  strictness: number;
  edit_area: string;
  edit_prompt: string;
}

/**
 *
 */
export class LabsApi extends runtime.BaseAPI {
  /**
   */
  async createCinemagraphRaw(
    requestParameters: LabsApiCreateCinemagraphRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    if (requestParameters.mask_range_max === null || requestParameters.mask_range_max === undefined) {
      throw new runtime.RequiredError(
        'mask_range_max',
        'Required parameter requestParameters.mask_range_max was null or undefined when calling createCinemagraph.',
      );
    }

    if (requestParameters.mask_range_min === null || requestParameters.mask_range_min === undefined) {
      throw new runtime.RequiredError(
        'mask_range_min',
        'Required parameter requestParameters.mask_range_min was null or undefined when calling createCinemagraph.',
      );
    }

    if (requestParameters.negative_prompt === null || requestParameters.negative_prompt === undefined) {
      throw new runtime.RequiredError(
        'negative_prompt',
        'Required parameter requestParameters.negative_prompt was null or undefined when calling createCinemagraph.',
      );
    }

    if (requestParameters.positive_prompt === null || requestParameters.positive_prompt === undefined) {
      throw new runtime.RequiredError(
        'positive_prompt',
        'Required parameter requestParameters.positive_prompt was null or undefined when calling createCinemagraph.',
      );
    }

    if (requestParameters.image === null || requestParameters.image === undefined) {
      throw new runtime.RequiredError('image', 'Required parameter requestParameters.image was null or undefined when calling createCinemagraph.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.mask_range_max !== undefined) {
      formParams.append('maskRangeMax', requestParameters.mask_range_max as any);
    }

    if (requestParameters.mask_range_min !== undefined) {
      formParams.append('maskRangeMin', requestParameters.mask_range_min as any);
    }

    if (requestParameters.negative_prompt !== undefined) {
      formParams.append('negativePrompt', requestParameters.negative_prompt as any);
    }

    if (requestParameters.positive_prompt !== undefined) {
      formParams.append('positivePrompt', requestParameters.positive_prompt as any);
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    const response = await this.request(
      {
        path: `/labs/cinemagraph/create`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async createCinemagraph(
    mask_range_max: number,
    mask_range_min: number,
    negative_prompt: string,
    positive_prompt: string,
    image: Blob,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Blob> {
    const response = await this.createCinemagraphRaw(
      { mask_range_max: mask_range_max, mask_range_min: mask_range_min, negative_prompt: negative_prompt, positive_prompt: positive_prompt, image: image },
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async inferEditActionRaw(
    requestParameters: LabsApiInferEditActionOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<InferEditActionResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/labs/conversational/infer-edit-action`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: InferEditActionRequestToJSON(requestParameters.infer_edit_action_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InferEditActionResponseFromJSON(jsonValue));
  }

  /**
   */
  async inferEditAction(
    infer_edit_action_request?: InferEditActionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<InferEditActionResponse> {
    const response = await this.inferEditActionRaw({ infer_edit_action_request: infer_edit_action_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async labsRestyleSegmentRaw(
    requestParameters: LabsApiLabsRestyleSegmentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    if (requestParameters.image === null || requestParameters.image === undefined) {
      throw new runtime.RequiredError('image', 'Required parameter requestParameters.image was null or undefined when calling labsRestyleSegment.');
    }

    if (requestParameters.strictness === null || requestParameters.strictness === undefined) {
      throw new runtime.RequiredError('strictness', 'Required parameter requestParameters.strictness was null or undefined when calling labsRestyleSegment.');
    }

    if (requestParameters.edit_area === null || requestParameters.edit_area === undefined) {
      throw new runtime.RequiredError('edit_area', 'Required parameter requestParameters.edit_area was null or undefined when calling labsRestyleSegment.');
    }

    if (requestParameters.edit_prompt === null || requestParameters.edit_prompt === undefined) {
      throw new runtime.RequiredError('edit_prompt', 'Required parameter requestParameters.edit_prompt was null or undefined when calling labsRestyleSegment.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    if (requestParameters.strictness !== undefined) {
      formParams.append('strictness', requestParameters.strictness as any);
    }

    if (requestParameters.edit_area !== undefined) {
      formParams.append('edit_area', requestParameters.edit_area as any);
    }

    if (requestParameters.edit_prompt !== undefined) {
      formParams.append('edit_prompt', requestParameters.edit_prompt as any);
    }

    const response = await this.request(
      {
        path: `/labs/conversational/labs-restyle-segment`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async labsRestyleSegment(
    image: Blob,
    strictness: number,
    edit_area: string,
    edit_prompt: string,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Blob> {
    const response = await this.labsRestyleSegmentRaw({ image: image, strictness: strictness, edit_area: edit_area, edit_prompt: edit_prompt }, initOverrides);
    return await response.value();
  }
}
