/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UserSettings
 */
export interface UserSettings {
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  openreplay: string;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  enable_client_side_logging: string;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  photoshoot_batch_size: string;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  default_editor_card_side: string;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  global_usage_tracking: string;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  new_editor_enabled: string;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  scene_creation_from_photo_enabled: string;
}

/**
 * Check if a given object implements the UserSettings interface.
 */
export function instanceOfUserSettings(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'openreplay' in value;
  isInstance = isInstance && 'enable_client_side_logging' in value;
  isInstance = isInstance && 'photoshoot_batch_size' in value;
  isInstance = isInstance && 'default_editor_card_side' in value;
  isInstance = isInstance && 'global_usage_tracking' in value;
  isInstance = isInstance && 'new_editor_enabled' in value;
  isInstance = isInstance && 'scene_creation_from_photo_enabled' in value;

  return isInstance;
}

export function UserSettingsFromJSON(json: any): UserSettings {
  return UserSettingsFromJSONTyped(json, false);
}

export function UserSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSettings {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    openreplay: json['openreplay'],
    enable_client_side_logging: json['enable_client_side_logging'],
    photoshoot_batch_size: json['photoshoot_batch_size'],
    default_editor_card_side: json['default_editor_card_side'],
    global_usage_tracking: json['global_usage_tracking'],
    new_editor_enabled: json['new_editor_enabled'],
    scene_creation_from_photo_enabled: json['scene_creation_from_photo_enabled'],
  };
}

export function UserSettingsToJSON(value?: UserSettings | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    openreplay: value.openreplay,
    enable_client_side_logging: value.enable_client_side_logging,
    photoshoot_batch_size: value.photoshoot_batch_size,
    default_editor_card_side: value.default_editor_card_side,
    global_usage_tracking: value.global_usage_tracking,
    new_editor_enabled: value.new_editor_enabled,
    scene_creation_from_photo_enabled: value.scene_creation_from_photo_enabled,
  };
}
