/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Asset
 */
export interface Asset {
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  product_id: string;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  original_url: string;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  transparent_url: string;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  custom_size: number;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  size: number;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  serial?: number;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  size_orientation: number;
  /**
   *
   * @type {Array<string>}
   * @memberof Asset
   */
  cropped: Array<string>;
}

/**
 * Check if a given object implements the Asset interface.
 */
export function instanceOfAsset(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'description' in value;
  isInstance = isInstance && 'product_id' in value;
  isInstance = isInstance && 'original_url' in value;
  isInstance = isInstance && 'transparent_url' in value;
  isInstance = isInstance && 'custom_size' in value;
  isInstance = isInstance && 'size' in value;
  isInstance = isInstance && 'size_orientation' in value;
  isInstance = isInstance && 'cropped' in value;

  return isInstance;
}

export function AssetFromJSON(json: any): Asset {
  return AssetFromJSONTyped(json, false);
}

export function AssetFromJSONTyped(json: any, ignoreDiscriminator: boolean): Asset {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    description: json['description'],
    product_id: json['product_id'],
    original_url: json['original_url'],
    transparent_url: json['transparent_url'],
    custom_size: json['custom_size'],
    size: json['size'],
    serial: !exists(json, 'serial') ? undefined : json['serial'],
    size_orientation: json['size_orientation'],
    cropped: json['cropped'],
  };
}

export function AssetToJSON(value?: Asset | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    description: value.description,
    product_id: value.product_id,
    original_url: value.original_url,
    transparent_url: value.transparent_url,
    custom_size: value.custom_size,
    size: value.size,
    serial: value.serial,
    size_orientation: value.size_orientation,
    cropped: value.cropped,
  };
}
