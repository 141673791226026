// @mui

import { GlobalStyles as MUIGlobalStyles, useTheme } from '@mui/material';

// ----------------------------------------------------------------------
import { FocusStyleManager } from '@blueprintjs/core';

export default function GlobalStyles() {
  const theme = useTheme();
  FocusStyleManager.onlyShowFocusOnTabs();
  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        ul: {
          margin: 0,
          padding: 0,
        },
        '.editor-container': {
          fontFamily: theme.typography.fontFamily,
          'button.bp5-button': {
            borderRadius: '8px',
          },
          '.bp5-input-group': {
            input: {
              borderRadius: '8px',
            },
          },
          '.bp5-card': {
            borderRadius: '8px',
          },
          '.bp5-control.bp5-switch input:checked ~ .bp5-control-indicator': {
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
            },
            backgroundColor: theme.palette.primary.main,
          },
          '.bp5-control.bp5-switch .bp5-control-indicator::before': {
            boxShadow: 'none',
          },

          'button.bp5-button.bp5-intent-primary': {
            backgroundColor: theme.palette.primary.main,
            border: '0px solid white',
            'box-shadow': 'none',
          },
          'button.bp5-button.bp5-intent-primary.bp5-disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
            color: theme.palette.action.disabled,
          },
          '.bp5-navbar': {
            boxShadow: '0px 1px 0px 1px rgba(0, 0, 0, 0.05)',
            height: '64px',
            padding: '7px 0px 0px 7px',
          },
          '.editor-toolbar>div': {
            backgroundColor: '#fff',
            color: '#212B36',

            // '-webkit-transition': 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            // transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            boxShadow: '0px 6px 4px -1px rgba(0, 0, 0, 0.2)',
          },
          '.polotno-side-panel': {
            boxShadow: '6px 0px 4px -1px rgba(0, 0, 0, 0.2)',
            border: '0px solid white',
          },
          '*': {
            fontFamily: theme.typography.fontFamily,
          },
          '.polotno-side-panel-tab': {
            padding: '15px',

            '&.active': {
              backgroundColor: '#fdecf4',
              color: theme.palette.primary.main,
            },
            '&:hover': {
              backgroundColor: '#fdecf4',
              color: theme.palette.primary.main,
            },
          },
          '.bp5-tab-list': {
            '.bp5-tab-indicator-wrapper': {
              '~ .bp5-tab[aria-selected=true]': {
                backgroundColor: 'blue',

                color: theme.palette.primary.main,
                boxShadow: `0px 0px -3px 0px ${theme.palette.primary.main}`,
              },
            },
            'tap-highlight-color': 'red',
          },
        },
        '.hoverTarget .visibleOnHover': {
          visibility: 'hidden',
          opacity: 0,
          transition: 'visibility 0s, opacity 0.2s linear',
        },
        '.hoverTarget:hover .visibleOnHover': {
          visibility: 'visible',
          opacity: 1,
        },
      }}
    />
  );

  return inputGlobalStyles;
}
