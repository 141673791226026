import { forwardRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// @mui
import { Box } from '@mui/material';
//
import getRatio from './getRatio';
import { ImageProps } from './types';

// ----------------------------------------------------------------------

const Image = forwardRef<HTMLSpanElement, ImageProps>(({ ratio, disabledEffect = false, effect = 'blur', sx, actions, ...other }, ref) => {
  const content = (
    <Box position='absolute' top={0} left={0} width='100%' height='100%'>
      <Box
        component={LazyLoadImage}
        wrapperClassName='wrapper'
        effect={disabledEffect ? undefined : effect}
        placeholderSrc={disabledEffect ? '/assets/transparent.png' : '/assets/placeholder.svg'}
        sx={{ width: '100%', height: '100%', objectFit: 'cover', ...sx }}
        {...other}
      />
      {actions && (
        <Box position='absolute' top={10} left={10}>
          {actions}
        </Box>
      )}
    </Box>
  );

  if (ratio) {
    return (
      <Box
        ref={ref}
        component='span'
        sx={{
          width: 1,
          lineHeight: 1,
          display: 'block',
          overflow: 'hidden',
          position: 'relative',
          pt: getRatio(ratio),
          '& .wrapper': {
            top: 0,
            left: 0,
            width: 1,
            height: 1,
            position: 'absolute',
            backgroundSize: 'cover !important',
          },
          ...sx,
        }}
      >
        {content}
      </Box>
    );
  }

  return (
    <Box
      ref={ref}
      component='span'
      sx={{
        lineHeight: 1,
        display: 'block',
        overflow: 'hidden',
        position: 'relative',
        '& .wrapper': {
          width: 1,
          height: 1,
          backgroundSize: 'cover !important',
        },
        ...sx,
      }}
    >
      {content}
    </Box>
  );
});

export default Image;
