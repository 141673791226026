import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import useResponsive from '../../hooks/useResponsive';
import { Dialog, DialogActions, DialogContent, IconButton } from '@mui/material';
import Iconify from '../iconify';
import AssetUploader from './AssetUploader';

interface UploadDialogContextValue {
  showUploadModal(): void;
  hideUploadModal(): void;
}

const UploadDialogContext = createContext<null | UploadDialogContextValue>(null);

export function useUploadDialogContext() {
  const value = useContext(UploadDialogContext);

  if (!value) {
    throw new Error('UploadDialogContext value not found');
  }

  return value;
}

export function UploadDialogContextProvider({ children }: { children?: ReactNode }) {
  const [isUploadDialogShown, setIsUploadDialogShown] = useState(false);
  const isDesktop = useResponsive('up', 'md');

  const uploadDialogContextValue = useMemo(
    () => ({
      showUploadModal: () => setIsUploadDialogShown(true),
      hideUploadModal: () => setIsUploadDialogShown(false),
    }),
    [setIsUploadDialogShown],
  );
  const { hideUploadModal } = uploadDialogContextValue;

  return (
    <UploadDialogContext.Provider value={uploadDialogContextValue}>
      {children}

      {isUploadDialogShown && (
        <Dialog open onClose={hideUploadModal} maxWidth='md' fullScreen={!isDesktop}>
          <DialogActions>
            <IconButton onClick={hideUploadModal}>
              <Iconify icon='material-symbols:close' />
            </IconButton>
          </DialogActions>
          <DialogContent>
            <AssetUploader onUpload={hideUploadModal} />
          </DialogContent>
          <DialogActions />
        </Dialog>
      )}
    </UploadDialogContext.Provider>
  );
}
