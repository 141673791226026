/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  CreatePhotoSessionPayload,
  CreateProductRequest,
  CreateSceneRequestPayload,
  GenerateScenePreviewResponse,
  GetPhotoSessionResponse,
  PhotoSession,
  Product,
  ProductListing,
  Scene,
  SuggestedScenesResponse,
  UpdatePhotoSessionPayload,
  UpdateUserSettingsRequest,
  User,
  UsersIdentityUpdatePostRequest,
  WalledGardenResponse,
} from '../models';
import {
  CreatePhotoSessionPayloadFromJSON,
  CreatePhotoSessionPayloadToJSON,
  CreateProductRequestFromJSON,
  CreateProductRequestToJSON,
  CreateSceneRequestPayloadFromJSON,
  CreateSceneRequestPayloadToJSON,
  GenerateScenePreviewResponseFromJSON,
  GenerateScenePreviewResponseToJSON,
  GetPhotoSessionResponseFromJSON,
  GetPhotoSessionResponseToJSON,
  PhotoSessionFromJSON,
  PhotoSessionToJSON,
  ProductFromJSON,
  ProductToJSON,
  ProductListingFromJSON,
  ProductListingToJSON,
  SceneFromJSON,
  SceneToJSON,
  SuggestedScenesResponseFromJSON,
  SuggestedScenesResponseToJSON,
  UpdatePhotoSessionPayloadFromJSON,
  UpdatePhotoSessionPayloadToJSON,
  UpdateUserSettingsRequestFromJSON,
  UpdateUserSettingsRequestToJSON,
  UserFromJSON,
  UserToJSON,
  UsersIdentityUpdatePostRequestFromJSON,
  UsersIdentityUpdatePostRequestToJSON,
  WalledGardenResponseFromJSON,
  WalledGardenResponseToJSON,
} from '../models';

export interface FlowApiBountyScenesSearchByTextGetRequest {
  search_term: string;
}

export interface FlowApiBountyScenesShowIdGetRequest {
  id: string;
}

export interface FlowApiCreatePhotoSessionRequest {
  create_photo_session_payload?: CreatePhotoSessionPayload;
}

export interface FlowApiCreateProductOperationRequest {
  create_product_request?: CreateProductRequest;
}

export interface FlowApiCreateSceneRequest {
  create_scene_request_payload?: CreateSceneRequestPayload;
}

export interface FlowApiEditorsPickRequest {
  max_results?: string;
}

export interface FlowApiGenerateScenePreviewRequest {
  placement_canvas_content: string;
  sample_asset_id: string;
  scene_name: string;
  inspiration1: Blob;
  scene_id?: string;
  negative_prompt?: string;
  positive_prompt?: string;
  inspiration2?: Blob;
}

export interface FlowApiGetPhotoSessionRequest {
  id: string;
}

export interface FlowApiProductSearchRequest {
  asset_id?: string;
  name?: string;
}

export interface FlowApiProductShowRequest {
  id: string;
}

export interface FlowApiSceneCategoriesRequest {
  size?: string;
}

export interface FlowApiSceneSearchRequest {
  product_id?: string;
  category?: string;
}

export interface FlowApiSuggestedScenesRequest {
  product_id: string;
  max_results?: string;
}

export interface FlowApiUpdatePhotoSessionRequest {
  id: string;
  update_photo_session_payload?: UpdatePhotoSessionPayload;
}

export interface FlowApiUpdateUserSettingsOperationRequest {
  update_user_settings_request?: UpdateUserSettingsRequest;
}

export interface FlowApiUsersIdentityUpdatePostOperationRequest {
  users_identity_update_post_request?: UsersIdentityUpdatePostRequest;
}

/**
 *
 */
export class FlowApi extends runtime.BaseAPI {
  /**
   */
  async bountyScenesSearchByTextGetRaw(
    requestParameters: FlowApiBountyScenesSearchByTextGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SuggestedScenesResponse>> {
    if (requestParameters.search_term === null || requestParameters.search_term === undefined) {
      throw new runtime.RequiredError(
        'search_term',
        'Required parameter requestParameters.search_term was null or undefined when calling bountyScenesSearchByTextGet.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.search_term !== undefined) {
      queryParameters['search_term'] = requestParameters.search_term;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/bounty/scenes/searchByText`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SuggestedScenesResponseFromJSON(jsonValue));
  }

  /**
   */
  async bountyScenesSearchByTextGet(search_term: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuggestedScenesResponse> {
    const response = await this.bountyScenesSearchByTextGetRaw({ search_term: search_term }, initOverrides);
    return await response.value();
  }

  /**
   */
  async bountyScenesShowIdGetRaw(
    requestParameters: FlowApiBountyScenesShowIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Scene>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling bountyScenesShowIdGet.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/bounty/scenes/show/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SceneFromJSON(jsonValue));
  }

  /**
   */
  async bountyScenesShowIdGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Scene> {
    const response = await this.bountyScenesShowIdGetRaw({ id: id }, initOverrides);
    return await response.value();
  }

  /**
   */
  async createPhotoSessionRaw(
    requestParameters: FlowApiCreatePhotoSessionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PhotoSession>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/bounty/photo_session`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreatePhotoSessionPayloadToJSON(requestParameters.create_photo_session_payload),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PhotoSessionFromJSON(jsonValue));
  }

  /**
   */
  async createPhotoSession(
    create_photo_session_payload?: CreatePhotoSessionPayload,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PhotoSession> {
    const response = await this.createPhotoSessionRaw({ create_photo_session_payload: create_photo_session_payload }, initOverrides);
    return await response.value();
  }

  /**
   */
  async createProductRaw(
    requestParameters: FlowApiCreateProductOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Product>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/bounty/products/new`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateProductRequestToJSON(requestParameters.create_product_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
  }

  /**
   */
  async createProduct(create_product_request?: CreateProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
    const response = await this.createProductRaw({ create_product_request: create_product_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async createSceneRaw(
    requestParameters: FlowApiCreateSceneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Scene>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/bounty/scenes/create`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateSceneRequestPayloadToJSON(requestParameters.create_scene_request_payload),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SceneFromJSON(jsonValue));
  }

  /**
   */
  async createScene(create_scene_request_payload?: CreateSceneRequestPayload, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Scene> {
    const response = await this.createSceneRaw({ create_scene_request_payload: create_scene_request_payload }, initOverrides);
    return await response.value();
  }

  /**
   */
  async editorsPickRaw(
    requestParameters: FlowApiEditorsPickRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SuggestedScenesResponse>> {
    const queryParameters: any = {};

    if (requestParameters.max_results !== undefined) {
      queryParameters['max_results'] = requestParameters.max_results;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/bounty/scenes/editors_pick`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SuggestedScenesResponseFromJSON(jsonValue));
  }

  /**
   */
  async editorsPick(max_results?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuggestedScenesResponse> {
    const response = await this.editorsPickRaw({ max_results: max_results }, initOverrides);
    return await response.value();
  }

  /**
   */
  async generateScenePreviewRaw(
    requestParameters: FlowApiGenerateScenePreviewRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GenerateScenePreviewResponse>> {
    if (requestParameters.placement_canvas_content === null || requestParameters.placement_canvas_content === undefined) {
      throw new runtime.RequiredError(
        'placement_canvas_content',
        'Required parameter requestParameters.placement_canvas_content was null or undefined when calling generateScenePreview.',
      );
    }

    if (requestParameters.sample_asset_id === null || requestParameters.sample_asset_id === undefined) {
      throw new runtime.RequiredError(
        'sample_asset_id',
        'Required parameter requestParameters.sample_asset_id was null or undefined when calling generateScenePreview.',
      );
    }

    if (requestParameters.scene_name === null || requestParameters.scene_name === undefined) {
      throw new runtime.RequiredError('scene_name', 'Required parameter requestParameters.scene_name was null or undefined when calling generateScenePreview.');
    }

    if (requestParameters.inspiration1 === null || requestParameters.inspiration1 === undefined) {
      throw new runtime.RequiredError(
        'inspiration1',
        'Required parameter requestParameters.inspiration1 was null or undefined when calling generateScenePreview.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.scene_id !== undefined) {
      formParams.append('sceneId', requestParameters.scene_id as any);
    }

    if (requestParameters.placement_canvas_content !== undefined) {
      formParams.append('placementCanvasContent', requestParameters.placement_canvas_content as any);
    }

    if (requestParameters.sample_asset_id !== undefined) {
      formParams.append('sampleAssetId', requestParameters.sample_asset_id as any);
    }

    if (requestParameters.negative_prompt !== undefined) {
      formParams.append('negativePrompt', requestParameters.negative_prompt as any);
    }

    if (requestParameters.positive_prompt !== undefined) {
      formParams.append('positivePrompt', requestParameters.positive_prompt as any);
    }

    if (requestParameters.scene_name !== undefined) {
      formParams.append('sceneName', requestParameters.scene_name as any);
    }

    if (requestParameters.inspiration2 !== undefined) {
      formParams.append('inspiration2', requestParameters.inspiration2 as any);
    }

    if (requestParameters.inspiration1 !== undefined) {
      formParams.append('inspiration1', requestParameters.inspiration1 as any);
    }

    const response = await this.request(
      {
        path: `/bounty/scenes/generate_scene_preview`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GenerateScenePreviewResponseFromJSON(jsonValue));
  }

  /**
   */
  async generateScenePreview(
    placement_canvas_content: string,
    sample_asset_id: string,
    scene_name: string,
    inspiration1: Blob,
    scene_id?: string,
    negative_prompt?: string,
    positive_prompt?: string,
    inspiration2?: Blob,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GenerateScenePreviewResponse> {
    const response = await this.generateScenePreviewRaw(
      {
        placement_canvas_content: placement_canvas_content,
        sample_asset_id: sample_asset_id,
        scene_name: scene_name,
        inspiration1: inspiration1,
        scene_id: scene_id,
        negative_prompt: negative_prompt,
        positive_prompt: positive_prompt,
        inspiration2: inspiration2,
      },
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async getPhotoSessionRaw(
    requestParameters: FlowApiGetPhotoSessionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GetPhotoSessionResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getPhotoSession.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/bounty/photo_session/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GetPhotoSessionResponseFromJSON(jsonValue));
  }

  /**
   */
  async getPhotoSession(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPhotoSessionResponse> {
    const response = await this.getPhotoSessionRaw({ id: id }, initOverrides);
    return await response.value();
  }

  /**
   */
  async identityRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/users/identity`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
  }

  /**
   */
  async identity(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
    const response = await this.identityRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async productSearchRaw(
    requestParameters: FlowApiProductSearchRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ProductListing>> {
    const queryParameters: any = {};

    if (requestParameters.asset_id !== undefined) {
      queryParameters['asset_id'] = requestParameters.asset_id;
    }

    if (requestParameters.name !== undefined) {
      queryParameters['name'] = requestParameters.name;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/bounty/products/search`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ProductListingFromJSON(jsonValue));
  }

  /**
   */
  async productSearch(asset_id?: string, name?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductListing> {
    const response = await this.productSearchRaw({ asset_id: asset_id, name: name }, initOverrides);
    return await response.value();
  }

  /**
   */
  async productShowRaw(
    requestParameters: FlowApiProductShowRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Product>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling productShow.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/bounty/products/show/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
  }

  /**
   */
  async productShow(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
    const response = await this.productShowRaw({ id: id }, initOverrides);
    return await response.value();
  }

  /**
   */
  async sceneCategoriesRaw(
    requestParameters: FlowApiSceneCategoriesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SuggestedScenesResponse>> {
    const queryParameters: any = {};

    if (requestParameters.size !== undefined) {
      queryParameters['size'] = requestParameters.size;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/bounty/scenes/categories`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SuggestedScenesResponseFromJSON(jsonValue));
  }

  /**
   */
  async sceneCategories(size?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuggestedScenesResponse> {
    const response = await this.sceneCategoriesRaw({ size: size }, initOverrides);
    return await response.value();
  }

  /**
   */
  async sceneSearchRaw(
    requestParameters: FlowApiSceneSearchRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SuggestedScenesResponse>> {
    const queryParameters: any = {};

    if (requestParameters.product_id !== undefined) {
      queryParameters['product_id'] = requestParameters.product_id;
    }

    if (requestParameters.category !== undefined) {
      queryParameters['category'] = requestParameters.category;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/bounty/scenes/search`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SuggestedScenesResponseFromJSON(jsonValue));
  }

  /**
   */
  async sceneSearch(product_id?: string, category?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuggestedScenesResponse> {
    const response = await this.sceneSearchRaw({ product_id: product_id, category: category }, initOverrides);
    return await response.value();
  }

  /**
   */
  async suggestedScenesRaw(
    requestParameters: FlowApiSuggestedScenesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SuggestedScenesResponse>> {
    if (requestParameters.product_id === null || requestParameters.product_id === undefined) {
      throw new runtime.RequiredError('product_id', 'Required parameter requestParameters.product_id was null or undefined when calling suggestedScenes.');
    }

    const queryParameters: any = {};

    if (requestParameters.max_results !== undefined) {
      queryParameters['max_results'] = requestParameters.max_results;
    }

    if (requestParameters.product_id !== undefined) {
      queryParameters['product_id'] = requestParameters.product_id;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/bounty/scenes/suggested_scenes`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SuggestedScenesResponseFromJSON(jsonValue));
  }

  /**
   */
  async suggestedScenes(
    product_id: string,
    max_results?: string,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SuggestedScenesResponse> {
    const response = await this.suggestedScenesRaw({ product_id: product_id, max_results: max_results }, initOverrides);
    return await response.value();
  }

  /**
   */
  async updatePhotoSessionRaw(
    requestParameters: FlowApiUpdatePhotoSessionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PhotoSession>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updatePhotoSession.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/bounty/photo_session/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: UpdatePhotoSessionPayloadToJSON(requestParameters.update_photo_session_payload),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PhotoSessionFromJSON(jsonValue));
  }

  /**
   */
  async updatePhotoSession(
    id: string,
    update_photo_session_payload?: UpdatePhotoSessionPayload,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PhotoSession> {
    const response = await this.updatePhotoSessionRaw({ id: id, update_photo_session_payload: update_photo_session_payload }, initOverrides);
    return await response.value();
  }

  /**
   */
  async updateUserSettingsRaw(
    requestParameters: FlowApiUpdateUserSettingsOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<User>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/users/settings/update`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateUserSettingsRequestToJSON(requestParameters.update_user_settings_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
  }

  /**
   */
  async updateUserSettings(
    update_user_settings_request?: UpdateUserSettingsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<User> {
    const response = await this.updateUserSettingsRaw({ update_user_settings_request: update_user_settings_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async userGeneratedScenesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuggestedScenesResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/bounty/scenes/user_generated`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SuggestedScenesResponseFromJSON(jsonValue));
  }

  /**
   */
  async userGeneratedScenes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuggestedScenesResponse> {
    const response = await this.userGeneratedScenesRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async usersIdentityUpdatePostRaw(
    requestParameters: FlowApiUsersIdentityUpdatePostOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<User>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/users/identity/update`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UsersIdentityUpdatePostRequestToJSON(requestParameters.users_identity_update_post_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
  }

  /**
   */
  async usersIdentityUpdatePost(
    users_identity_update_post_request?: UsersIdentityUpdatePostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<User> {
    const response = await this.usersIdentityUpdatePostRaw({ users_identity_update_post_request: users_identity_update_post_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async walledgardenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WalledGardenResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/public/walledgarden`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => WalledGardenResponseFromJSON(jsonValue));
  }

  /**
   */
  async walledgarden(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WalledGardenResponse> {
    const response = await this.walledgardenRaw(initOverrides);
    return await response.value();
  }
}
