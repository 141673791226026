import { Stack, StackProps, Typography } from '@mui/material';
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router';
import { AppCacheContext } from 'src/components/flow/AppCacheContext';
import { Upload } from 'src/components/upload';
import Iconify from '../iconify';
import { UploadByUrl } from '../upload/UploadByUrl';

const ACCEPTED_MIMETYPES = ['image/jpeg', 'image/png', 'image/webp', 'image/avif', 'image/gif'];

function Placeholder(props: StackProps) {
  return (
    <Stack alignItems='center' justifyContent='center' direction='column' textAlign='center' p={{ xs: 0, sm: 5 }} pb={{ xs: 0, sm: 8 }} {...props}>
      <Iconify icon='eva:cloud-upload-fill' width={90} sx={{ mb: 4, color: (theme) => theme.palette.primary.main }} />

      <Typography variant='h3'>Drag and drop a file here or click to browse...</Typography>
      <Typography variant='subtitle2' color='text.disabled'>
        Accepted formats: jpeg, png, gif, webp
      </Typography>
    </Stack>
  );
}

interface AssetUploaderProps {
  onUpload: () => void;
}

export default function AssetUploader({ onUpload }: AssetUploaderProps) {
  let [_cache, setCache] = useContext(AppCacheContext);
  const navigate = useNavigate();

  const uploadAsset = useCallback(
    async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      if (file) {
        setCache((cache) => {
          let clone = cache.clone();
          clone.file = file;
          clone.asset = undefined;
          return clone;
        });
        navigate(`/assets/edit`);
        onUpload();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onUpload],
  );

  return (
    <Stack direction='column' gap={4}>
      <Upload
        multiple={false}
        onDrop={uploadAsset}
        accept={Object.fromEntries(ACCEPTED_MIMETYPES.map((mimetype) => [mimetype, []]))}
        placeholder={<Placeholder />}
      />
      <div>
        Alternatively, provide a file url
        <UploadByUrl onFetched={uploadAsset} acceptedMimetypes={ACCEPTED_MIMETYPES} />
      </div>
    </Stack>
  );
}
