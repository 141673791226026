import { Box, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import { CreditsContextProvider } from 'src/components/credits-context/CreditsContext';
import DesktopHeader from 'src/components/editor/DesktopHeader';
import MobileHeader from 'src/components/editor/MobileHeader';
import { AppCacheProvider } from 'src/components/flow/AppCacheContext';
import { UploadDialogContextProvider } from 'src/components/upload-dialog-context/UploadDialogContext';
import useResponsive from 'src/hooks/useResponsive';

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

export default function CADLayout() {
  const isDesktop = useResponsive('up', 'md');
  return (
    <CreditsContextProvider>
      <AppCacheProvider>
        <UploadDialogContextProvider>
          {isDesktop ? (
            <Box pb={6} height='100%'>
              <DesktopHeader />

              <Outlet />
              <Chip
                label={process.env.REACT_APP_ENV}
                color='error'
                size={isDesktop ? 'medium' : 'small'}
                sx={{
                  position: 'fixed',
                  bottom: isDesktop ? '10px' : '40px',
                  fontSize: !isDesktop ? '8px' : undefined,
                  right: '10px',
                  display: process.env.REACT_APP_ENV !== 'production' ? undefined : 'none',
                }}
              />
            </Box>
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <MobileHeader />
              <Offset />
              <Box sx={{ py: 2, overflow: 'auto' }}>
                <Outlet />
              </Box>
            </Box>
          )}
        </UploadDialogContextProvider>
      </AppCacheProvider>
    </CreditsContextProvider>
  );
}
